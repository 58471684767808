import { Typography } from "antd"

export const phasesData = [
  {
    dot: <div className={"timeline-dot"}>1</div>,
    children: (
      <div className={"timeline-label"}>
        <Typography.Title level={3} className={"timeline-label-title"}>
          Survey and Inventory
        </Typography.Title>
        <Typography.Text className={"timeline-label-text"}>
          The first stage conducts a thorough survey to gather relevant data and assess social media platform status.
        </Typography.Text>
      </div>
    ),
  },
  {
    dot: <div className={"timeline-dot"}>2</div>,
    children: (
      <div className={"timeline-label"}>
        <Typography.Title level={3} className={"timeline-label-title"}>
          Data Collection
        </Typography.Title>
        <Typography.Text className={"timeline-label-text"}>
          This stage focuses on collecting comprehensive data from various sources, including social media platforms.
        </Typography.Text>
      </div>
    ),
  },
  {
    dot: <div className={"timeline-dot"}>3</div>,
    children: (
      <div className={"timeline-label"}>
        <Typography.Title level={3} className={"timeline-label-title"}>
          Qualification of Accounts
        </Typography.Title>
        <Typography.Text className={"timeline-label-text"}>
          Evaluating social media accounts based on predefined criteria.
        </Typography.Text>
      </div>
    ),
  },
  {
    dot: <div className={"timeline-dot"}>4</div>,
    children: (
      <div className={"timeline-label"}>
        <Typography.Title level={3} className={"timeline-label-title"}>
          Evaluation Mechanism
        </Typography.Title>
        <Typography.Text className={"timeline-label-text"}>
          Assessing performance and engagement levels.
        </Typography.Text>
      </div>
    ),
  },
  {
    dot: <div className={"timeline-dot"}>5</div>,
    children: (
      <div className={"timeline-label"}>
        <Typography.Title level={3} className={"timeline-label-title"}>
          Statistics and Results
        </Typography.Title>
        <Typography.Text className={"timeline-label-text"}>
          Analyzing data to provide insights and recommendations.
        </Typography.Text>
      </div>
    ),
  },
]
