import { Col, Flex, Row, Typography } from "antd"
import { FC, useState } from "react"

import { useApp } from "../../app"

import { ReactComponent as ArrowIcon } from "../../../assets/icons/arrow-down.svg"

import { platformsLabels, statsAccountsLabel, statsQualifiedLabels } from "../../../shared/fake-data/overviewChartsData"

import { BarChart } from "../../charts/BarChart/BarChart"
import { MultiLineGraph } from "../../charts/MultiLineGraph"
import { DashboardCardTemplate, OverviewRanking, Select } from "../../ui"
import { RankingMinistries } from "../RankingMinistries"

import { ComplexChartType, DisplayMode, Mode, Role } from "../../../types/enums"

import dayjs from "dayjs"
import { useBreakpoints } from "../../../hooks/useBreakpoints"

import "./OverviewAllPlatformsSection.less"

const chartTypes: ComplexChartType[] = [
  ComplexChartType.Followers,
  ComplexChartType.Likes,
  ComplexChartType.Comments,
  ComplexChartType.Posts,
]

const OverviewAllPlatformsSection: FC<{
  tab: string
  mode: Mode
  data: any
  displayMode: DisplayMode | null
  role: Role
}> = ({ mode, displayMode, role, data, tab }) => {
  const { isArab } = useApp()
  const { isMobile, isDesktop } = useBreakpoints()
  const [chartType, setChartType] = useState(chartTypes[0])
  const user = "Ministry of Health"
  const isMinistries = displayMode === DisplayMode.Ministries || mode === Mode.All
  const isMinistriesAndOrganizations =
    (displayMode === DisplayMode.MinistriesAndOrganizations || mode === Mode.All) &&
    role !== Role.Ministry &&
    role !== Role.PrimeMinister
  const isArabWorld = displayMode === DisplayMode.ArabWorld || mode === Mode.All
  const localIndex = data.local_match_profile
  const topLocalPositionsData = data.local_all_profile.map((it: { p_avatar: any; overall_rank: any; profile: any }) => {
    return {
      src: it.p_avatar,
      title: it.overall_rank,
      description: it.profile,
    }
  })
  const withOrgsIndex = data.ministry_organization_match_profile
  const topWithOrgsPositionsData = data.ministry_organization_all_profile.map(
    (it: { p_avatar: any; overall_rank: any; profile: any }) => {
      return {
        src: it.p_avatar,
        title: it.overall_rank,
        description: it.profile,
      }
    }
  )
  const arabWorldIndex = data.arab_world_match_profile
  const topArabWorldPositionsData = data.arab_world_all_profile.map(
    (it: { p_avatar: any; overall_rank: any; profile: any }) => {
      return {
        src: it.p_avatar,
        title: it.overall_rank,
        description: it.profile,
      }
    }
  )

  const days = []

  for (let i = 0; i < 18; i++) {
    days.push(dayjs().subtract(i, "days").format("DD MMM"))
  }

  const complexFollowersData = {
    labels: days.reverse(),
    datasets: [
      {
        label: "Instagram",
        borderColor: "#6048F3",
        data:
          chartType === ComplexChartType.Followers
            ? data.instagram_profile.followers_series.slice(-18)
            : chartType === ComplexChartType.Likes
              ? data.instagram_profile.likes_series.slice(-18)
              : chartType === ComplexChartType.Comments
                ? data.instagram_profile.comments_series.slice(-18)
                : data.instagram_profile.posts_series.slice(-18),
      },
      {
        label: "Facebook",
        borderColor: "#219AFF",
        data:
          chartType === ComplexChartType.Followers
            ? data.facebook_profile.followers_series.slice(-18)
            : chartType === ComplexChartType.Likes
              ? data.facebook_profile.likes_series.slice(-18)
              : chartType === ComplexChartType.Comments
                ? data.facebook_profile.comments_series.slice(-18)
                : data.facebook_profile.posts_series.slice(-18),
      },
      {
        label: "Youtube",
        borderColor: "#DA1818",
        data:
          chartType === ComplexChartType.Followers
            ? data.youtube_profile.followers_series.slice(-18)
            : chartType === ComplexChartType.Likes
              ? data.youtube_profile.likes_series.slice(-18)
              : chartType === ComplexChartType.Comments
                ? data.youtube_profile.comments_series.slice(-18)
                : [],
      },
      {
        label: "X",
        borderColor: "#757A89",
        data:
          chartType === ComplexChartType.Followers
            ? data.twitter_profile.followers_series.slice(-18)
            : chartType === ComplexChartType.Likes
              ? data.twitter_profile.likes_series.slice(-18)
              : chartType === ComplexChartType.Comments
                ? data.twitter_profile.comments_series.slice(-18)
                : data.twitter_profile.posts_series.slice(-18),
      },
    ],
  }

  return (
    <Flex vertical gap={24}>
      {isMinistries &&
        (role === Role.Ministry || role === Role.MinistryOfKuwait ? (
          <OverviewRanking
            tab={tab}
            user={localIndex.profile}
            country={localIndex.country_name}
            mode={DisplayMode.Ministries}
            platformsRanking={{
              facebook: localIndex.Facebook_rank,
              instagram: localIndex.Instagram_rank,
              youtube: localIndex.Youtube_rank,
              x: localIndex.Twitter_rank,
            }}
            place={localIndex.overall_rank}
            totalPlaces={data.total_profile}
            evaluation={localIndex.overall_rank_score}
            topPositions={topLocalPositionsData}
          />
        ) : (
          <RankingMinistries
            tagText={DisplayMode.Ministries}
            user={user}
            ministriesData={data?.local_all_profile}
            totalCountries={data?.local_all_profile.length}
            role={role}
            tab={tab}
          />
        ))}
      {isMinistriesAndOrganizations &&
        (role === Role.MinistryOfKuwait ? (
          <OverviewRanking
            tab={tab}
            user={withOrgsIndex.profile}
            country={withOrgsIndex.country_name}
            mode={DisplayMode.MinistriesAndOrganizations}
            platformsRanking={{
              facebook: withOrgsIndex.Facebook_rank,
              instagram: withOrgsIndex.Instagram_rank,
              youtube: withOrgsIndex.Youtube_rank,
              x: withOrgsIndex.Twitter_rank,
            }}
            place={withOrgsIndex.overall_rank}
            totalPlaces={data.total_profile}
            evaluation={withOrgsIndex.overall_rank_score}
            topPositions={topWithOrgsPositionsData}
          />
        ) : (
          <RankingMinistries
            tagText={DisplayMode.MinistriesAndOrganizations}
            ministriesData={data?.ministry_organization_all_profile}
            totalCountries={data?.ministry_organization_all_profile.length}
            role={role}
            user={user}
            tab={tab}
          />
        ))}
      {isArabWorld &&
        (role === Role.Ministry || role === Role.MinistryOfKuwait ? (
          <OverviewRanking
            tab={tab}
            user={arabWorldIndex.profile}
            country={arabWorldIndex.country_name}
            mode={DisplayMode.ArabWorld}
            platformsRanking={{
              facebook: arabWorldIndex.Facebook_rank,
              instagram: arabWorldIndex.Instagram_rank,
              youtube: arabWorldIndex.Youtube_rank,
              x: arabWorldIndex.Twitter_rank,
            }}
            place={arabWorldIndex.overall_rank}
            totalPlaces={data.total_profile}
            evaluation={arabWorldIndex.overall_rank_score}
            topPositions={topArabWorldPositionsData}
          />
        ) : (
          <RankingMinistries
            tagText={DisplayMode.ArabWorld}
            user={user}
            ministriesData={data?.arab_world_all_profile}
            totalCountries={data?.arab_world_all_profile.length}
            role={role}
            tab={tab}
          />
        ))}
      <Row justify={"space-between"} gutter={[24, 24]} style={{ direction: isArab ? "rtl" : "ltr" }}>
        <Col span={!isDesktop ? 24 : 12}>
          <DashboardCardTemplate
            title={
              <Flex gap={16} style={{ marginBottom: 22 }} vertical={isMobile}>
                <Typography.Title level={3} className={"dashboard-card-title"} style={{ marginBottom: 0 }}>
                  Statistics
                </Typography.Title>
                <Typography.Text className={"mode-tag blue"} style={{ position: "static" }}>
                  {localIndex.country_name}
                </Typography.Text>
              </Flex>
            }
            style={{ height: "100%" }}
          >
            <Typography.Paragraph className={"overview-stats-text-blue"}>
              <Typography.Text className={"overview-stats-text-blue big"}>{data.total_profile}</Typography.Text>{" "}
              ministries
            </Typography.Paragraph>
            <Row className={"bar-charts-row-wrapper"} gutter={[36, 24]}>
              <Col xs={24} sm={24} md={12}>
                <BarChart
                  labels={statsQualifiedLabels}
                  data={[data.chart_profile.overall_verified, data.chart_profile.overall_pending]}
                  tickStep={20}
                  minTick={0}
                  height={300}
                  displayPercentsAndValues={true}
                />
                <Typography.Text
                  className={"overview-stats-text"}
                  style={{ marginTop: 28, marginLeft: 20, fontSize: 14 }}
                >
                  <Typography.Text className={"overview-stats-text big"} style={{ fontSize: 18, fontWeight: 500 }}>
                    {data.chart_profile.overall_exist}
                  </Typography.Text>{" "}
                  Existing Social Media Accounts
                </Typography.Text>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <BarChart
                  labels={statsAccountsLabel}
                  data={[data.chart_profile.overall_exist, data.chart_profile.overall_not_exist]}
                  tickStep={20}
                  minTick={0}
                  height={300}
                  displayPercentsAndValues={true}
                />
                <Typography.Text
                  className={"overview-stats-text"}
                  style={{ marginTop: 28, marginLeft: 20, fontSize: 14 }}
                >
                  <Typography.Text className={"overview-stats-text big"} style={{ fontSize: 18, fontWeight: 500 }}>
                    {data.chart_profile.overall_total}
                  </Typography.Text>{" "}
                  Assumed Social Media Accounts
                </Typography.Text>
              </Col>
            </Row>
          </DashboardCardTemplate>
        </Col>
        <Col span={!isDesktop ? 24 : 12}>
          <DashboardCardTemplate
            title={
              <Flex gap={16} style={{ marginBottom: 24 }} vertical={isMobile}>
                <Typography.Title level={3} className={"dashboard-card-title"} style={{ marginBottom: 0 }}>
                  Most Used Platform
                </Typography.Title>
                <Typography.Text className={"mode-tag blue"} style={{ position: "static" }}>
                  {localIndex.country_name}
                </Typography.Text>
              </Flex>
            }
            style={{ height: "100%" }}
          >
            <BarChart
              labels={platformsLabels}
              data={[
                data.chart_profile.instagram_exist,
                data.chart_profile.twitter_exist,
                data.chart_profile.youtube_exist,
                data.chart_profile.facebook_exist,
              ]}
              height={360}
              tickStep={20}
              minTick={0}
              maxTick={100}
              displayValuesLikePercents={true}
            />
          </DashboardCardTemplate>
        </Col>
      </Row>
      <DashboardCardTemplate title={"Complex Diagram"}>
        <div className={`complex-chart-container ${isArab && "rtl"}`}>
          <MultiLineGraph data={complexFollowersData} />
          <Select
            suffixIcon={<ArrowIcon />}
            selectedValue={chartType}
            setChartType={setChartType}
            options={chartTypes}
            className={"complex-select"}
          />
        </div>
        <Flex
          align={"center"}
          gap={32}
          className={"chart-legend"}
          style={{ marginTop: 32, direction: isArab ? "rtl" : "ltr" }}
        >
          {complexFollowersData.datasets.map(it => (
            <Flex gap={10} align={"center"} key={it.label}>
              <div style={{ background: it.borderColor, width: "12px", height: "12px", borderRadius: "50%" }}></div>
              <Typography className={"chart-legend-label"}>{it.label}</Typography>
            </Flex>
          ))}
        </Flex>
      </DashboardCardTemplate>
    </Flex>
  )
}

export { OverviewAllPlatformsSection }
