import { Flex, Row, Typography } from "antd"
import { FC, useState } from "react"

import { useApp } from "../../app"

import { ReactComponent as ArrowIcon } from "../../../assets/icons/arrow-down.svg"

import { complexFollowersData } from "../../../shared/fake-data/overviewChartsData"

import { StatsCards } from "src/components/ui/Stats/StatsCards"
import { DashboardCardTemplate, OverviewRanking, ProgressBar, Select } from "../../ui"

import { ComplexChartType, DisplayMode, Mode, Role } from "../../../types/enums"

import { PlatformTabLabel } from "../../../types/dataTypes"
import { MultiLineGraph } from "../../charts/MultiLineGraph"
import { PercentageDifference } from "../../ui/PercentageDifference"

import { RankingMinistries } from "../RankingMinistries"

import { App } from "../../../types"
import "./OverviewOnePlatformSection.less"

const chartTypes: ComplexChartType[] = [
  ComplexChartType.Followers,
  ComplexChartType.Likes,
  ComplexChartType.Comments,
  ComplexChartType.Posts,
]

const OverviewOnePlatformSection: FC<{
  tab: string
  mode: Mode
  displayMode: DisplayMode | null
  tabContentKey: PlatformTabLabel
  data: any
  role: Role
}> = ({ tab, mode, data, displayMode, tabContentKey, role }) => {
  const [chartType, setChartType] = useState(chartTypes[0])

  const { isArab } = useApp()

  console.log(data)

  const user = "Ministry of Health"
  const number = 12.12

  const isMinistries = displayMode === DisplayMode.Ministries || mode === Mode.All
  const isMinistriesAndOrganizations =
    (displayMode === DisplayMode.MinistriesAndOrganizations || mode === Mode.All) &&
    role !== Role.Ministry &&
    role !== Role.PrimeMinister
  const isArabWorld = displayMode === DisplayMode.ArabWorld || mode === Mode.All
  const localIndex = data.local_match_profile
  const topLocalPositionsData = data.local_all_profile.map((it: { p_avatar: any; overall_rank: any; profile: any }) => {
    return {
      src: it.p_avatar,
      title: it.overall_rank,
      description: it.profile,
    }
  })
  const withOrgsIndex = data.ministry_organization_match_profile
  const topWithOrgsPositionsData = data.ministry_organization_all_profile.map(
    (it: { p_avatar: any; overall_rank: any; profile: any }) => {
      return {
        src: it.p_avatar,
        title: it.overall_rank,
        description: it.profile,
      }
    }
  )
  const arabWorldIndex = data.arab_world_match_profile
  const topArabWorldPositionsData = data.arab_world_all_profile.map(
    (it: { p_avatar: any; overall_rank: any; profile: any }) => {
      return {
        src: it.p_avatar,
        title: it.overall_rank,
        description: it.profile,
      }
    }
  )

  const chartDataset =
    tabContentKey === "Instagram"
      ? {
          label: "Instagram",
          borderColor: "#6048F3",
          data:
            chartType === ComplexChartType.Followers
              ? data.instagram_profile.followers_series.slice(-18)
              : chartType === ComplexChartType.Likes
                ? data.instagram_profile.likes_series.slice(-18)
                : chartType === ComplexChartType.Comments
                  ? data.instagram_profile.comments_series.slice(-18)
                  : data.instagram_profile.posts_series.slice(-18),
        }
      : tabContentKey === "Youtube"
        ? {
            label: "Youtube",
            borderColor: "#DA1818",
            data:
              chartType === ComplexChartType.Followers
                ? data.youtube_profile.followers_series.slice(-18)
                : chartType === ComplexChartType.Likes
                  ? data.youtube_profile.likes_series.slice(-18)
                  : chartType === ComplexChartType.Comments
                    ? data.youtube_profile.comments_series.slice(-18)
                    : [],
          }
        : {
            label: "X",
            borderColor: "#757A89",
            data:
              chartType === ComplexChartType.Followers
                ? data.twitter_profile.followers_series.slice(-18)
                : chartType === ComplexChartType.Likes
                  ? data.twitter_profile.likes_series.slice(-18)
                  : chartType === ComplexChartType.Comments
                    ? data.twitter_profile.comments_series.slice(-18)
                    : data.twitter_profile.posts_series.slice(-18),
          }

  const chartData = {
    labels: complexFollowersData.labels,
    borderColor: "#219AFF",
    datasets: chartDataset ? [chartDataset] : [],
  }

  const engagementData =
    tabContentKey === "Instagram"
      ? data.instagram_profile.day_engagement_series.map((item: string, index: number) => {
          return {
            label: data.instagram_profile.day_label_series[index],
            value: item,
          }
        })
      : tabContentKey === "Youtube"
        ? []
        : data.twitter_profile.day_engagement_series.map((item: string, index: number) => {
            return {
              label: data.twitter_profile.day_label_series[index],
              value: item,
            }
          })

  const statsCardsData =
    tabContentKey === "Instagram"
      ? [
          {
            title: "Followers",
            number: +data.instagram_profile.followers_stat,
            gainedNumber: +data.instagram_profile.followers_gained_stat,
          },
          {
            title: "Likes",
            number: +data.instagram_profile.likes_stat,
          },
          {
            title: "Comments",
            number: +data.instagram_profile.comments_stat,
          },
          {
            title: "Posts",
            number: +data.instagram_profile.posts_stat,
          },
        ]
      : tabContentKey === "Youtube"
        ? [
            {
              title: "Followers",
              number: +data.youtube_profile.followers_stat,
              gainedNumber: +data.youtube_profile.followers_gained_stat,
            },
            {
              title: "Likes",
              number: +data.youtube_profile.likes_stat,
            },
            {
              title: "Comments",
              number: +data.youtube_profile.comments_stat,
            },
          ]
        : [
            {
              title: "Followers",
              number: +data.twitter_profile.followers_stat,
              gainedNumber: +data.twitter_profile.followers_gained_stat,
            },
            {
              title: "Likes",
              number: +data.twitter_profile.likes_stat,
            },
            {
              title: "retweets",
              number: +data.twitter_profile.comments_stat,
            },
            {
              title: "Posts",
              number: +data.twitter_profile.posts_stat,
            },
          ]

  return (
    <Flex vertical gap={24}>
      {isMinistries &&
        (role === Role.Ministry || role === Role.MinistryOfKuwait ? (
          <OverviewRanking
            tab={tab}
            user={localIndex.profile}
            country={localIndex.country_name}
            mode={DisplayMode.Ministries}
            platformsRanking={{
              facebook: localIndex.Facebook_rank,
              instagram: localIndex.Instagram_rank,
              youtube: localIndex.Youtube_rank,
              x: localIndex.Twitter_rank,
            }}
            place={localIndex.overall_rank}
            totalPlaces={data.total_profile}
            evaluation={localIndex.overall_rank_score}
            topPositions={topLocalPositionsData}
          />
        ) : (
          <RankingMinistries
            tagText={DisplayMode.Ministries}
            user={user}
            ministriesData={data?.local_all_profile}
            totalCountries={data?.local_all_profile.length}
            role={role}
            tab={tab}
          />
        ))}
      {isMinistriesAndOrganizations &&
        (role === Role.MinistryOfKuwait ? (
          <OverviewRanking
            tab={tab}
            user={withOrgsIndex.profile}
            country={withOrgsIndex.country_name}
            mode={DisplayMode.MinistriesAndOrganizations}
            platformsRanking={{
              facebook: withOrgsIndex.Facebook_rank,
              instagram: withOrgsIndex.Instagram_rank,
              youtube: withOrgsIndex.Youtube_rank,
              x: withOrgsIndex.Twitter_rank,
            }}
            place={withOrgsIndex.overall_rank}
            totalPlaces={data.total_profile}
            evaluation={withOrgsIndex.overall_rank_score}
            topPositions={topWithOrgsPositionsData}
          />
        ) : (
          <RankingMinistries
            tagText={DisplayMode.MinistriesAndOrganizations}
            ministriesData={data?.ministry_organization_all_profile}
            totalCountries={data?.ministry_organization_all_profile.length}
            role={role}
            user={user}
            tab={tab}
          />
        ))}
      {isArabWorld &&
        (role === Role.Ministry || role === Role.MinistryOfKuwait ? (
          <OverviewRanking
            tab={tab}
            user={arabWorldIndex.profile}
            country={arabWorldIndex.country_name}
            mode={DisplayMode.ArabWorld}
            platformsRanking={{
              facebook: arabWorldIndex.Facebook_rank,
              instagram: arabWorldIndex.Instagram_rank,
              youtube: arabWorldIndex.Youtube_rank,
              x: arabWorldIndex.Twitter_rank,
            }}
            place={arabWorldIndex.overall_rank}
            totalPlaces={data.total_profile}
            evaluation={arabWorldIndex.overall_rank_score}
            topPositions={topArabWorldPositionsData}
          />
        ) : (
          <RankingMinistries
            tagText={DisplayMode.ArabWorld}
            user={user}
            ministriesData={data?.arab_world_all_profile}
            totalCountries={data?.arab_world_all_profile.length}
            role={role}
            tab={tab}
          />
        ))}
      <Row className={"one-platform-charts-wrapper"} style={{ direction: isArab ? "rtl" : "ltr" }}>
        <DashboardCardTemplate title={"Complex Diagram"}>
          <Flex align={"center"} gap={8} style={{ marginBottom: 32 }}>
            <PercentageDifference number={number} size={"large"} />
            <Typography.Paragraph className={"lines-chart-extra-text"} type={"secondary"}>
              from last month
            </Typography.Paragraph>
          </Flex>
          <div className={`complex-chart-container ${isArab && "rtl"}`}>
            <MultiLineGraph data={chartData} />
            <Select
              suffixIcon={<ArrowIcon />}
              selectedValue={chartType}
              setChartType={setChartType}
              options={chartTypes}
              className={"complex-select"}
            />
          </div>
        </DashboardCardTemplate>
        {tabContentKey !== "Youtube" && (
          <DashboardCardTemplate title={"Engagement Days"}>
            <ProgressBar items={engagementData} />
          </DashboardCardTemplate>
        )}
      </Row>
      <StatsCards data={statsCardsData} />
    </Flex>
  )
}

export { OverviewOnePlatformSection }
