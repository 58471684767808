import clsx from "clsx"

import { Flex, Typography } from "antd"
import React, { CSSProperties } from "react"

import { ReactComponent as LinearCourseDown } from "src/assets/icons/stats/linear-course-down.svg"
import { ReactComponent as LinearCourseUp } from "src/assets/icons/stats/linear-course-up.svg"

import "./index.less"

type PercentageDifferenceProps = {
  style?: CSSProperties
  className?: string
  number: number
  size?: "small" | "large"
}

export const PercentageDifference: React.FC<PercentageDifferenceProps> = ({
  style,
  className,
  number,
  size = "small",
}) => {
  return (
    <Flex
      style={style}
      align={"center"}
      className={clsx("percentage-difference-container", `percentage-difference-container-${size}`, className)}
    >
      {number >= 0 ? (
        <React.Fragment>
          <LinearCourseUp />
          <Typography className={"increasing-number"}>{number.toFixed(2)}%</Typography>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <LinearCourseDown />
          <Typography className={"decreasing-number"}>{number.toFixed(2)}%</Typography>
        </React.Fragment>
      )}
    </Flex>
  )
}
