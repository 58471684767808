import axios from "axios"
import { baseHeaders, baseUrl } from "./baseUrl"
import { useAuthStore } from "src/store/auth"

export const logout = (): Promise<false | void> => {
  return axios
    .post(
      `${baseUrl}logout`,
      {},
      {
        headers: { ...baseHeaders, Authorization: `Bearer ${localStorage.getItem("jwt")}` },
      }
    )
    .then(response => {
      response.data.status === 1 && localStorage.removeItem("jwt")
      useAuthStore.getState().set(store => {
        store.loggedIn = false
      })
    })
    .catch(error => console.log("error:", error.message))
}
