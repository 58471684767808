import { Select as SelectInput } from "antd"
import { FC, ReactNode } from "react"

import { useApp } from "../../app"

import { ComplexChartType, DisplayMode } from "../../../types/enums"

import "./Select.less"

const Select: FC<{
  suffixIcon: ReactNode
  selectedValue: DisplayMode | ComplexChartType | string | null
  setDisplayMode?: (displayMode: DisplayMode) => void
  setChartType?: (selectedValue: ComplexChartType) => void
  setDay?: (selectedValue: string) => void
  options: DisplayMode[] | ComplexChartType[] | string[]
  className: "display-mode-select" | "complex-select" | "day-select"
}> = ({ suffixIcon, selectedValue, setDisplayMode, setDay, setChartType, options, className }) => {
  const { isArab } = useApp()

  const handleChange = (value: DisplayMode | ComplexChartType | string) => {
    setDisplayMode && setDisplayMode(value as DisplayMode)
    setChartType && setChartType(value as ComplexChartType)
    setDay && setDay(value)
  }

  return (
    <SelectInput
      suffixIcon={suffixIcon}
      value={selectedValue}
      onChange={value => handleChange(value)}
      // className={className}
      className={isArab ? `arab ${className}` : className}
      style={{ direction: isArab ? "rtl" : "ltr" }}
    >
      {options.map(option => (
        <SelectInput.Option key={option} value={option} className={isArab ? "arab-option" : "option"}>
          {option}
        </SelectInput.Option>
      ))}
    </SelectInput>
  )
}

export { Select }
