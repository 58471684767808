import { Draft, Immutable, produce } from "immer"
import { create } from "zustand"

type AuthState = {
  loggedIn: boolean | undefined
}

type AuthActions = {
  set: (cb: (store: Draft<AuthState>) => void) => void
}

export type AuthStore = Immutable<AuthState & AuthActions>

export const useAuthStore = create<AuthStore>(setStore => ({
  set: cb => setStore(produce<AuthStore>(cb)),

  loggedIn: false,
}))
