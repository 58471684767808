import React from "react"

import { Flex, Typography } from "antd"

import { CompactLineGraph } from "src/components/charts/CompactLineGraph"

import { toNumberWithComma } from "src/helpers/toNumberWithComma"

import { PercentageDifference } from "../PercentageDifference"
import "./StatCard.less"

export type StatCardProps = {
  title: string
  number: number
  gainedNumber?: number
}

export const StatCard: React.FC<StatCardProps> = ({ title, number, gainedNumber }) => {
  const diff = gainedNumber ? (gainedNumber / (number - gainedNumber)) * 100 : 0

  return (
    <Flex vertical className={"stat-card-container"}>
      <Typography.Paragraph type={"secondary"} className={"stat-card-title"}>
        {title}
      </Typography.Paragraph>
      <Flex align={"center"} justify={"space-between"} gap={12}>
        <div>
          <PercentageDifference style={{ marginBottom: 8 }} number={diff} />
          <Typography.Title level={1}>{toNumberWithComma(number || 0)}</Typography.Title>
        </div>
        <span style={{ width: 88, height: 56 }}>
          <CompactLineGraph />
        </span>
      </Flex>
      <Typography.Paragraph type={"secondary"} className={"stat-card-last-month"}>
        {toNumberWithComma(gainedNumber ? number - gainedNumber : number)} last month
      </Typography.Paragraph>
    </Flex>
  )
}
