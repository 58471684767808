import { Button, Modal, Typography } from "antd"
import { FC } from "react"

import { useApp } from "../../app"

import onboardingImg from "../../../assets/images/onboarding.png"

import "./WelcomeModal.less"

const WelcomeModal: FC<{ isOpen: boolean; handleClick: () => void }> = ({ isOpen, handleClick }) => {
  const { isArab } = useApp()

  return (
    <Modal
      open={isOpen}
      footer={null}
      closable={false}
      className={"welcome-modal"}
      width={605}
      style={{ direction: isArab ? "rtl" : "ltr" }}
    >
      <img src={onboardingImg} alt={"Welcome to Pointer!"} />
      <div className={"welcome-modal-info-wrapper"}>
        <Typography.Title className={"welcome-modal-title"}>
          Welcome to <Typography.Text className={"welcome-modal-title blue"}>Pointer!</Typography.Text>
        </Typography.Title>
        <Typography.Paragraph className={"welcome-modal-text"}>
          We're excited to offer you a personalized tour to showcase the full potential of the Pointer Social Media
          Reporting Dashboard.
        </Typography.Paragraph>
        <Button type={"primary"} onClick={handleClick} className={"welcome-modal-btn"}>
          Let’s go!
        </Button>
      </div>
    </Modal>
  )
}

export { WelcomeModal }
