import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Tooltip,
} from "chart.js"

import { Line } from "react-chartjs-2"
import { formatter } from "src/helpers/numberFormatter"
import { useApp } from "../app"

ChartJS.register(LineController, LineElement, PointElement, LinearScale, CategoryScale, Filler, Tooltip)

export type MultiLineGraphProps = {
  data: {
    labels: string[]
    datasets: {
      label: string
      borderColor: string
      data: number[]
    }[]
  }
  chartLineOptions?: any
  dataOptions?: any
}

export const MultiLineGraph: React.FC<MultiLineGraphProps> = ({ data, chartLineOptions, dataOptions }) => {
  const { isArab } = useApp()

  return (
    <Line
      options={{
        maintainAspectRatio: false,
        responsive: true,
        interaction: {
          mode: "index",
          intersect: false,
        },
        elements: {
          point: {
            radius: 0,
          },
        },
        plugins: {
          legend: { display: false },
          tooltip: {
            enabled: true,
            intersect: false,
            backgroundColor: "#fff",
            bodyColor: "#757A89",
            titleColor: "#757A89",
            titleFont: {
              family: "Inter",
              size: 12,
              lineHeight: 1.66,
              weight: 500,
            },
            bodyFont: {
              family: "Inter",
              size: 12,
              lineHeight: 1.66,
              weight: 400,
            },
            cornerRadius: 8,
            callbacks: {
              label: context => {
                if (context.dataset.label?.includes("Outcome")) {
                  return ` ${context.dataset.label}: -${context.raw}`
                }
                return ` ${context.dataset.label}: ${context.raw}`
              },
            },
          },
        },
        scales: {
          x: {
            grid: { color: "#F3F3F5" },
            reverse: isArab,
          },
          yMain: {
            type: "linear",
            display: true,
            position: isArab ? "right" : "left",
            grid: { color: "#F3F3F5" },
            border: { dash: [12] },
            ticks: {
              callback: value => {
                if (value === 0) {
                  return 0
                }

                if (typeof value === "number") {
                  return formatter.format(value)
                }

                return value
              },
            },
          },
          y: {
            display: false,
          },
        },
        ...chartLineOptions,
      }}
      data={{
        labels: data.labels,
        datasets: data.datasets.map((dataset, i) => ({
          ...dataset,
          yAxisID: i === 0 ? "yMain" : undefined,
          tension: 0.3,
          fill: true,
          backgroundColor: context => {
            if (!context.chart.chartArea) {
              return
            }

            const {
              ctx,
              chartArea: { top, bottom },
            } = context.chart

            const gradienBg = ctx.createLinearGradient(0, top, 0, bottom)

            gradienBg.addColorStop(0, `${dataset.borderColor}44`)
            gradienBg.addColorStop(1, "rgba(255, 255, 255, 0)")

            return gradienBg
          },
        })),
        ...dataOptions,
      }}
    />
  )
}
