import { Button, Flex, Modal, Typography } from "antd"
import { FC, Fragment } from "react"

import { useApp } from "../../app"

import { ReactComponent as AiTourIcon } from "../../../assets/icons/ai-tour.svg"
import { ReactComponent as CloseIcon } from "../../../assets/icons/x-close.svg"

import "./AIModal.less"

const items = [
  {
    title: "1. Facebook Integration",
    text: "Integrate Facebook to enhance our digital footprint, attract a larger audience, and improve customer engagement through targeted interactions on a widely-used platform.",
  },
  {
    title: "2. Twitter Activity",
    text: "Leverage Twitter for timely updates, industry news, and customer support, enhancing real-time communication and maintaining a dynamic online presence.",
  },
  {
    title: "3. Instagram Engagement",
    text: "Utilize Instagram to connect with a younger demographic, increase brand visibility, and foster community engagement through visual content and interactive features.",
  },
]

const AIModal: FC<{ open?: boolean; handleClose: () => void }> = ({ open, handleClose }) => {
  const { isArab } = useApp()

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      footer={null}
      closeIcon={<CloseIcon />}
      className={`ai-modal ${isArab && "rtl"}`}
      width={378}
      style={{ direction: isArab ? "rtl" : "ltr" }}
    >
      <Flex vertical gap={24}>
        <Flex align={"center"} gap={8}>
          <Typography.Title>AI Recommendation</Typography.Title>
          <AiTourIcon />
        </Flex>
        <Fragment>
          {items.map(item => (
            <div key={item.title}>
              <Typography.Paragraph>{item.title}</Typography.Paragraph>
              <Typography.Text>{item.text}</Typography.Text>
            </div>
          ))}
        </Fragment>
        <Button type={"primary"} onClick={handleClose}>
          Mark as read
        </Button>
      </Flex>
    </Modal>
  )
}

export { AIModal }
