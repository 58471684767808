import { Button, Col, Divider, Row, Typography } from "antd"
import { FC } from "react"

import { useApp } from "../../app"

import { Logo } from "../../ui/Logo"

import "./Footer.less"

const Footer: FC = () => {
  const { isArab } = useApp()

  return (
    <div className={"container landing"}>
      <Row justify={"space-between"} className={"footer-wrapper"} style={{ direction: isArab ? "rtl" : "ltr" }}>
        <Col>
          <Logo place={"footer"} width={122} height={40} />
        </Col>
        <Col>
          <Row className={"footer-info-wrapper"}>
            <Col className={"links-wrapper"}>
              <Button type={"link"} className={"footer-link"}>
                Company
              </Button>
              <Button type={"link"} className={"footer-link"}>
                Privacy Policy
              </Button>
              <Button type={"link"} className={"footer-link"}>
                Terms
              </Button>
            </Col>
            <Col>
              <Typography.Title level={3} className={"footer-col-title"}>
                Contacts
              </Typography.Title>
              <Row className={"contacts-wrapper"}>
                <Typography.Text className={"footer-contacts"}>United States</Typography.Text>
                <Typography.Text>Stevens Creek Blvd, Suite 100</Typography.Text>
                <Typography.Text>Cupertino, CA 95014</Typography.Text>
                <Typography.Text>408 973 7240</Typography.Text>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider />
      <Typography.Text className={"footer-rights"} style={{ textAlign: isArab ? "end" : "start" }}>
        @ 2024 Pointer. All Rights Reserved
      </Typography.Text>
    </div>
  )
}

export { Footer }
