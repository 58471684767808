import { Col, Divider, Row, Typography } from "antd"
import { FC } from "react"

import { useApp } from "../../app"

import { ReactComponent as AwardIcon } from "../../../assets/icons/menu/award.svg"
import { ReactComponent as FacebookIcon } from "../../../assets/icons/platforms/facebook.svg"
import { ReactComponent as InstagramIcon } from "../../../assets/icons/platforms/instagram.svg"
import { ReactComponent as XIcon } from "../../../assets/icons/platforms/x.svg"
import { ReactComponent as YoutubeIcon } from "../../../assets/icons/platforms/youtube.svg"
import { ReactComponent as StarsIcon } from "../../../assets/icons/stars.svg"

import { ListItem, PlatformsRanking } from "../../../types/dataTypes"
import { DisplayMode } from "../../../types/enums"

import { DashboardCardTemplate } from "../DashboardCardTemplate/DashboardCardTemplate"

import "./OverviewRanking.less"

const OverviewRanking: FC<{
  tab: string
  user: string
  location?: string
  country?: string
  mode: DisplayMode
  platformsRanking: PlatformsRanking
  place: number
  totalPlaces: number
  evaluation: number | string
  topPositions: ListItem[]
}> = ({ tab, user, location, country, mode, platformsRanking, place, totalPlaces, evaluation, topPositions }) => {
  const { isArab } = useApp()

  const tagColor =
    mode === DisplayMode.Ministries ? "pink" : mode === DisplayMode.MinistriesAndOrganizations ? "blue" : "purple"
  const userIndexTitle = mode !== DisplayMode.ArabWorld ? `${user} in ${country} Index` : `${user} in Index`
  const firstPositionTitle =
    mode !== DisplayMode.ArabWorld ? `First Positions in ${location} in ${country} Index` : `First Positions in Index`

  const formatPlace = (number: number | string): string => {
    if (number === 11 ?? number === 12) {
      return `${number}th`
    }
    const lastDigit = number.toString().slice(-1)
    return `${number}${lastDigit === "1" ? "st" : lastDigit === "2" ? "nd" : lastDigit === "3" ? "rd" : "th"}`
  }

  const getPlace = () => {
    switch (tab) {
      case "All platforms": {
        return formatPlace(place)
      }
      case "Instagram": {
        return formatPlace(platformsRanking.instagram)
      }
      case "Youtube": {
        return formatPlace(platformsRanking.youtube)
      }
      case "X": {
        return formatPlace(platformsRanking.x)
      }
      case "Facebook": {
        return formatPlace(platformsRanking.facebook)
      }
      default: {
        return "-"
      }
    }
  }

  return (
    <Row
      justify={"space-between"}
      className={"overview-ranking-wrapper"}
      gutter={[24, 24]}
      align={"stretch"}
      style={{ direction: isArab ? "rtl" : "ltr" }}
    >
      <Col md={24} lg={12}>
        <DashboardCardTemplate title={userIndexTitle}>
          <Typography.Text className={`mode-tag ${tagColor}`}>{mode}</Typography.Text>
          <Row className={"overview-ranking-content-wrapper"} justify={"space-between"} gutter={[8, 24]}>
            <Col xs={24} sm={24} md={12} lg={12}>
              <div className={"overview-general-ranking-wrapper"}>
                <Typography.Paragraph className={"overview-general-ranking-label"}>Ranking</Typography.Paragraph>
                <Row align={"middle"} justify={"space-between"} style={{ marginBottom: 6 }}>
                  <Typography.Text className={"overview-general-ranking-value"}>{getPlace()}</Typography.Text>
                  <div className={"overview-general-ranking-icon"}>
                    <AwardIcon />
                  </div>
                </Row>
                <Typography.Text className={"overview-general-ranking-text"}>
                  out of {totalPlaces} ministries
                </Typography.Text>
                <Divider />
                <Typography.Paragraph className={"overview-general-ranking-label"}>Evaluation</Typography.Paragraph>
                <Row align={"middle"} justify={"space-between"} style={{ marginBottom: 6 }}>
                  <Typography.Text className={"overview-general-ranking-value"}>{evaluation}</Typography.Text>
                  <div className={"overview-general-ranking-icon"}>
                    <StarsIcon />
                  </div>
                </Row>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Row className={"overview-platforms-ranking-list"} gutter={[0, 28]}>
                <Col className={"list-item"} span={12}>
                  <InstagramIcon className={"platform-icon"} />
                  <Typography.Text>{formatPlace(platformsRanking.instagram)}</Typography.Text>
                </Col>
                <Col className={"list-item"} span={12}>
                  <FacebookIcon className={"platform-icon"} />
                  <Typography.Text>{formatPlace(platformsRanking.facebook)}</Typography.Text>
                </Col>
                <Col className={"list-item"} span={12}>
                  <YoutubeIcon className={"platform-icon"} />
                  <Typography.Text>{formatPlace(platformsRanking.youtube)}</Typography.Text>
                </Col>
                <Col className={"list-item"} span={12}>
                  <XIcon className={"platform-icon"} />
                  <Typography.Text>{formatPlace(platformsRanking.x)}</Typography.Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </DashboardCardTemplate>
      </Col>
      <Col md={24} lg={12}>
        <DashboardCardTemplate title={firstPositionTitle}>
          <Typography.Text className={`mode-tag ${tagColor}`}>{mode}</Typography.Text>
          <Row className={"overview-ranking-content-wrapper"} justify={"space-between"} gutter={[48, 24]}>
            {topPositions.map(position => (
              <Col key={position.title} xs={24} sm={24} md={8}>
                <img
                  src={typeof position.src === "string" ? position.src : ""}
                  alt={position.description}
                  className={"overview-general-ranking-image"}
                />
                <Typography.Paragraph
                  className={`overview-general-ranking-place ${position.description === user && "active"}`}
                >
                  {formatPlace(position.title)}
                </Typography.Paragraph>
                <Typography.Text
                  className={`overview-general-ranking-user ${position.description === user && "active"}`}
                >
                  {position.description}
                </Typography.Text>
              </Col>
            ))}
          </Row>
        </DashboardCardTemplate>
      </Col>
    </Row>
  )
}

export { OverviewRanking }
