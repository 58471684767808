import { ProgressData } from "../../types/dataTypes"

export const statsQualifiedLabels = ["Qualified", "Not Qualified"]
export const statsAccountsLabel = ["Exist", "Not Exist"]
export const platformsLabels = ["Instagram", "X", "Youtube", "Facebook"]

export const statsQualifiedData = [10, 62]
export const statsAccountsData = [72, 4]
export const platformsData = [76, 76, 70, 60]

export const engagementData: ProgressData[] = [
  {
    label: "Monday",
    value: 260000,
  },
  {
    label: "Tuesday",
    value: 210000,
  },
  {
    label: "Wednesday",
    value: 150000,
  },
  {
    label: "Thursday",
    value: 150000,
  },
  {
    label: "Friday",
    value: 210000,
  },
  {
    label: "Saturday",
    value: 280000,
  },
  {
    label: "Sunday",
    value: 250000,
  },
]

export const complexFollowersData = {
  labels: [
    "14 May",
    "15 May",
    "16 May",
    "17 May",
    "18 May",
    "19 May",
    "20 May",
    "21 May",
    "22 May",
    "23 May",
    "24 May",
    "25 May",
    "26 May",
    "27 May",
    "28 May",
    "29 May",
    "30 May",
    "31 May",
  ],
  datasets: [
    {
      label: "Instagram",
      borderColor: "#6048F3",
      data: [
        94233, 158323, 124321, 221323, 187213, 81234, 201234, 198098, 190234, 212234, 208234, 235234, 213234, 332234,
        321234, 345234, 332234, 364234, 401234,
      ],
    },
    {
      label: "Facebook",
      borderColor: "#219AFF",
      data: [
        194233, 178323, 154321, 131323, 117213, 108234, 101234, 98098, 95234, 97234, 108234, 115234, 103234, 100234,
        101234, 95234, 83234, 54234, 40234,
      ],
    },
    {
      label: "Youtube",
      borderColor: "#DA1818",
      data: [
        74233, 68323, 74321, 71323, 57213, 72234, 78234, 83098, 81234, 82234, 82294, 75234, 71234, 69234, 68234, 70234,
        73234, 64234, 60234,
      ],
    },
    {
      label: "X",
      borderColor: "#757A89",
      data: [
        244233, 228323, 284321, 231323, 207213, 292234, 368098, 353098, 357234, 378234, 372294, 365234, 357234, 349234,
        358234, 364234, 362234, 394234, 312234,
      ],
    },
  ],
}
