import { Layout as BaseLayout, Button, Flex, Tour, TourProps, Typography } from "antd"
import { FC, Suspense, useRef, useState } from "react"
import { Outlet, useLocation } from "react-router-dom"

import { useMediaQuery } from "react-responsive"
import { useApp } from "../app"

import detailedInsights from "../../assets/images/tabs/detailed-insights.png"
import generalInfo from "../../assets/images/tabs/general-info.png"
import overview from "../../assets/images/tabs/overview-tab.png"
import ranking from "../../assets/images/tabs/ranking.png"

import { ReactComponent as AiTourIcon } from "../../assets/icons/ai-tour.svg"
import { ReactComponent as AiIcon } from "../../assets/icons/ai.svg"

import { Footer, Header } from "."
import { AIModal, WelcomeModal } from "../ui"

const authPages = ["/login", "/forgot-password", "/reset-password"]
const platformPages = ["/overview", "/detailed-insights", "/ranking-and-evaluation", "/general-info"]

const Layout: FC = () => {
  const path = useLocation().pathname
  const isAuthPage = authPages.includes(path)
  const isPlatform = platformPages.includes(path)

  const isLargeDesktop = useMediaQuery({ minWidth: 1250 })
  const { isArab } = useApp()

  const [isWelcomeOpen, setIsWelcomeOpen] = useState(() => {
    const isOnboarded = localStorage.getItem("onboarded")
    return !isPlatform ? false : isOnboarded ? !JSON.parse(isOnboarded) : true
  })
  const [isAiOpen, setIsAiOpen] = useState(false)
  const [isOnboarding, setIsOnboarding] = useState(false)

  const overviewRef = useRef(null)
  const detailsRef = useRef(null)
  const rankingRef = useRef(null)
  const generalRef = useRef(null)
  const aiRef = useRef(null)

  const handleGoClick = () => {
    setIsWelcomeOpen(false)
    setIsOnboarding(true)
  }

  const handleCloseClick = () => {
    localStorage.setItem("onboarded", JSON.stringify(true))
    setIsOnboarding(false)
  }

  const handleAiBtnClick = () => setIsAiOpen(prevState => !prevState)

  const steps: TourProps["steps"] = [
    {
      cover: !isLargeDesktop ? <img src={overview} className={"small"} alt={"Overview"} /> : null,
      title: "Overview",
      description: "This tab is where you'll find the important data that should show on first glance.",
      target: isLargeDesktop ? overviewRef.current : null,
      mask: {
        color: "rgb(12 16 26 / 70%)",
      },
    },
    {
      cover: !isLargeDesktop ? <img src={detailedInsights} className={"big"} alt={"Detailed Insights"} /> : null,
      title: "Detailed Insights",
      description:
        "Insights contains detailed data obtained from a social media account on the following platforms: Facebook, X, Instagram, Youtube. The extracted data refer to a period of 3 months.",
      target: isLargeDesktop ? detailsRef.current : null,
      mask: {
        color: "rgb(12 16 26 / 70%)",
      },
    },
    {
      cover: !isLargeDesktop ? <img src={ranking} className={"big"} alt={"Ranking & Evaluation"} /> : null,
      title: "Ranking & Evaluation",
      description:
        "Businesses in the industry are evaluated and ranked based on three key criteria, each with a maximum score of 33.33: Engagement, Number of Posts, Number of Followers. The overall ranking is derived from the sum of these three scores, providing a comprehensive evaluation of the business's performance across all platforms.",
      target: isLargeDesktop ? rankingRef.current : null,
      mask: {
        color: "rgb(12 16 26 / 70%)",
      },
    },
    {
      cover: !isLargeDesktop ? <img src={generalInfo} className={"small"} alt={"General Info"} /> : null,
      title: "General Info",
      description:
        "Get insights into the organization, its definition, evolution, team members, features, and phases. Also, discover statistics and evaluations for Kuwait and the Arab world.",
      target: isLargeDesktop ? generalRef.current : null,
      mask: {
        color: "rgb(12 16 26 / 70%)",
      },
    },
    {
      title: (
        <Flex align={"center"} gap={8}>
          <Typography.Text>AI Recommendation</Typography.Text> <AiTourIcon />
        </Flex>
      ),
      description: "This a separate page to give a list of advice generated by AI.",
      target: aiRef.current,
      mask: {
        color: "rgb(12 16 26 / 70%)",
      },
    },
  ]

  return (
    <BaseLayout style={{ display: "flex", flexDirection: "column", alignItems: "stretch", minHeight: "100vh" }}>
      {path !== "/" && !isAuthPage && (
        <BaseLayout.Header ref={overviewRef} className={`header-wrapper ${isArab && "rtl"}`}>
          <Header
            rankingRef={rankingRef}
            overviewRef={overviewRef}
            generalRef={generalRef}
            detailsRef={detailsRef}
            setIsOnboarding={setIsWelcomeOpen}
          />
          <Tour
            open={isOnboarding}
            closeIcon={<Typography.Text>Dismiss</Typography.Text>}
            disabledInteraction={true}
            steps={steps}
            onClose={handleCloseClick}
            indicatorsRender={(current, total) => (
              <Typography.Text>
                {current + 1} of {total}
              </Typography.Text>
            )}
          />
        </BaseLayout.Header>
      )}
      <BaseLayout.Content>
        <Suspense>
          <Outlet />
        </Suspense>
        <WelcomeModal isOpen={isWelcomeOpen} handleClick={handleGoClick} />
        {path !== "/" && !isAuthPage && (
          <Button ref={aiRef} type={"link"} onClick={handleAiBtnClick} className={`ai-btn ${isArab && "rtl"}`}>
            <AiIcon />
          </Button>
        )}
        <AIModal open={isAiOpen} handleClose={handleAiBtnClick} />
      </BaseLayout.Content>
      {path === "/" && (
        <BaseLayout.Footer style={{ padding: 0, backgroundColor: "#080E26" }}>
          <Footer />
        </BaseLayout.Footer>
      )}
    </BaseLayout>
  )
}

export { Layout }
