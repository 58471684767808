import { ThemeConfig } from "antd/es/config-provider"

const theme: ThemeConfig = {
  inherit: false,
  token: {
    fontFamily: "'Inter', sans-serif",
    colorText: "#181818",
  },
  components: {
    Menu: {
      padding: 0,
      colorBorderBg: "unset",
      colorActiveBarHeight: 0,
      colorPrimaryBg: "unset",
    },
    Layout: {
      colorBgLayout: "unset",
      colorBgHeader: "unset",
      colorBgBody: "unset",
    },
    Button: {
      colorPrimary: "#4480FF",
      borderRadius: 8,
      paddingContentHorizontal: 32,
      colorText: "#4480FF",
      colorBorder: "#4480FF",
      colorPrimaryHover: "#2F6AE7",
      colorPrimaryActive: "#2F6AE7",
    },
    Checkbox: {
      colorPrimary: "#4480FF",
    },
  },
}

export default theme
