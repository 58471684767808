export enum DisplayMode {
  Ministries = "Ministries Locally",
  MinistriesAndOrganizations = "Ministries and Organizations Locally",
  ArabWorld = "Arab world",
}

export enum Mode {
  All = "all",
  Select = "select",
}

export enum ComplexChartType {
  Followers = "Followers",
  Likes = "Likes",
  Comments = "Comments",
  Posts = "Posts",
}

export enum Role {
  Ministry = "Ministry",
  MinistryOfKuwait = "Ministry of Kuwait",
  PrimeMinister = "Prime Minister",
  PrimeMinisterOfKuwait = "Prime Minister of Kuwait",
}
