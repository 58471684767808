import { BarElement, CategoryScale, Chart as ChartJS, LinearScale, Plugin, Tooltip } from "chart.js"
import { FC } from "react"
import { Bar } from "react-chartjs-2"

import { useApp } from "../app"

import { formatter } from "src/helpers/numberFormatter"

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip)

export type BarsGraphProps = {
  data: {
    labels: string[]
    datasets: {
      label?: string
      data: number[]
      color: string
    }[]
  }
}

export const BarsGraph: FC<BarsGraphProps> = ({ data }) => {
  const { isArab } = useApp()

  const rightYTicks: Plugin = {
    id: "rightYTicks",
    afterDatasetsDraw: chart => {
      const {
        ctx,
        scales,
        chartArea: { right },
      } = chart
      scales.y.ticks.map((tick, i) => {
        const label = (tick.label as string) || tick.value
        ctx.font = "14px Inter"
        ctx.fillStyle = "#757A89"
        ctx.fillText(label.toString(), right + 35, scales.y.getPixelForTick(i))
      })
    },
  }

  let plugins = []

  isArab && plugins.push(rightYTicks)

  return (
    <Bar
      plugins={plugins}
      options={{
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: { display: false },
          tooltip: {
            enabled: data.datasets.length > 1,
            intersect: false,
            backgroundColor: "#fff",
            bodyColor: "#757A89",
            titleColor: "#757A89",
            titleFont: {
              family: "Inter",
              size: 12,
              lineHeight: 1.66,
              weight: 500,
            },
            bodyFont: {
              family: "Inter",
              size: 12,
              lineHeight: 1.66,
              weight: 400,
            },
            cornerRadius: 8,
            callbacks: {
              label: context => {
                if (context.dataset.label === "Outcoming") {
                  return ` ${context.dataset.label}: -${context.raw}`
                }
                return ` ${context.dataset.label}: ${context.raw}`
              },
            },
          },
        },
        layout: {
          padding: isArab ? 70 : 0,
        },
        scales: {
          x: {
            grid: {
              color: "#F3F3F5",
              offset: false,
              drawTicks: false,
            },
            border: { color: "#757A89" },
            ticks: {
              padding: 10,
            },
            reverse: isArab,
          },
          y: {
            grid: { color: "#F3F3F5" },
            border: {
              display: !isArab,
              dash: [12],
            },
            ticks: {
              display: !isArab,
              padding: 15,
              font: {
                size: 14,
                lineHeight: 1.21,
              },
              color: "#757A89",
              callback: value => {
                if (value === 0) {
                  return 0
                }

                if (typeof value === "number") {
                  return formatter.format(value)
                }

                return value
              },
            },
          },
        },
      }}
      data={{
        labels: data.labels,
        datasets: data.datasets.map(dataset => ({
          data: dataset.data,
          backgroundColor: dataset.color,
          label: dataset.label,
          borderRadius: 2,
          barPercentage: data.datasets.length > 1 ? 1 : 0.5,
        })),
      }}
    />
  )
}
