import { Col, Divider, Flex, Image, Row, Typography } from "antd"
import { FC, useEffect, useState } from "react"
import { SvgIcon } from "../../../assets/icons"
import { useBreakpoints } from "../../../hooks/useBreakpoints"
import { App } from "../../../types"
import { DisplayMode, Role } from "../../../types/enums"
import { useApp } from "../../app"
import { ExpandedRankingCardContent } from "../../ui"
import { ContentBox } from "../../ui/ContentBox"
import { CustomBtn } from "../../ui/CustomBtn"
import "./index.less"

interface RankingMinistriesProps {
  tagText: string
  isStats?: boolean
  ministriesData: App.MinistryData[] | null
  role: Role
  user: string
  totalCountries: number | null | undefined
  tab: string
}

export const RankingMinistries: FC<RankingMinistriesProps> = ({
  tagText,
  isStats = false,
  role,
  user,
  ministriesData,
  totalCountries,
  tab,
}) => {
  const { isArab } = useApp()
  const { isMobile, isTablet, isDesktop, isMD } = useBreakpoints()
  const [currentSelectedMinistry] = useState<App.MinistryData | null>(() => {
    const ministry = ministriesData && ministriesData.find(it => it.profile === user)
    return ministry || null
  })
  const [visibleMinistries, setVisibleMinistries] = useState(isDesktop ? 10 : isTablet ? 8 : isMD ? 6 : 4)

  const isExpandedCard = role === Role.PrimeMinisterOfKuwait || role === Role.PrimeMinister
  const isOneRow = role === Role.PrimeMinisterOfKuwait

  const cardsVisible = {
    desktop: isOneRow ? 5 : 10,
    tablet: !isExpandedCard ? 8 : isOneRow ? 3 : 6,
    md: !isExpandedCard ? 6 : isOneRow ? 2 : 4,
    min: !isExpandedCard ? 4 : 2,
  }

  useEffect(() => {
    setVisibleMinistries(
      isDesktop ? cardsVisible.desktop : isTablet ? cardsVisible.tablet : isMD ? cardsVisible.md : cardsVisible.min
    )
  }, [isMobile, isTablet, isDesktop, isMD])

  const getOrdinalSuffix = (num: number) => {
    let suffix = "th"
    if (num % 10 === 1 && num % 100 !== 11) {
      suffix = "st"
    } else if (num % 10 === 2 && num % 100 !== 12) {
      suffix = "nd"
    } else if (num % 10 === 3 && num % 100 !== 13) {
      suffix = "rd"
    }
    return `${num}${suffix}`
  }

  const handleViewMore = () => {
    setVisibleMinistries(prev => prev + visibleMinistries)
  }

  const getAverage = (
    facebook: number | undefined,
    instagram: number | undefined,
    youtube: number | undefined,
    x: number | undefined
  ) => {
    return (Number(facebook) + Number(instagram) + Number(youtube) + Number(x)) / 4
  }

  const getStats = (ministry: App.MinistryData | null, statsType: "Engagement" | "Posts" | "Fans") => {
    const statsIndex = statsType === "Engagement" ? "2" : statsType === "Posts" ? "1" : "0"

    switch (tab) {
      case "Facebook": {
        return ministry?.socialList["0"]?.points[statsIndex]
      }
      case "Instagram": {
        return ministry?.socialList["1"]?.points[statsIndex]
      }
      case "Youtube": {
        return ministry?.socialList["2"]?.points[statsIndex]
      }
      case "X": {
        return ministry?.socialList["3"]?.points[statsIndex]
      }
      default: {
        return getAverage(
          ministry?.socialList["0"]?.points[statsIndex],
          ministry?.socialList["1"]?.points[statsIndex],
          ministry?.socialList["2"]?.points[statsIndex],
          ministry?.socialList["3"]?.points[statsIndex]
        ).toFixed(2)
      }
    }
  }

  const getEvaluation = (
    followers: string | number | undefined,
    posts: string | number | undefined,
    engagement: string | number | undefined
  ) => {
    return (Number(followers) + Number(posts) + Number(engagement)).toFixed(2)
  }

  const stats = [
    {
      title: "Ranking",
      value: currentSelectedMinistry?.overall_rank
        ? getOrdinalSuffix(currentSelectedMinistry?.overall_rank)
        : "Unknown",
      description: `out of ${totalCountries} countries`,
      iconType: "ranking",
    },
    {
      title: "Evaluation",
      value:
        getEvaluation(
          getStats(currentSelectedMinistry, "Engagement"),
          getStats(currentSelectedMinistry, "Posts"),
          getStats(currentSelectedMinistry, "Fans")
        ) || "Unknown",
      description: `out of 100`,
      iconType: "evaluation",
    },
    {
      title: "Engagement",
      value: getStats(currentSelectedMinistry, "Engagement") || "Unknown",
      description: `out of 33.33`,
      iconType: "engagement",
    },
    {
      title: "Posts",
      value: getStats(currentSelectedMinistry, "Posts") || "Unknown",
      description: `out of 33.33`,
      iconType: "posts",
    },
    {
      title: "Followers",
      value: getStats(currentSelectedMinistry, "Fans") || "Unknown",
      description: `out of 33.33`,
      iconType: "followers",
    },
  ]

  const tagColor =
    tagText === DisplayMode.Ministries ? "pink" : tagText === DisplayMode.MinistriesAndOrganizations ? "blue" : "purple"

  const currentMinistryIndex = ministriesData?.findIndex(it => it.profile === user)
  const currentMinistry = ministriesData?.find(it => it.profile === user)
  const slicedData = ministriesData?.slice(0, visibleMinistries)
  const shortSlicedData = slicedData?.slice(0, visibleMinistries - 1)
  const isCurrentMinistry = role !== Role.PrimeMinisterOfKuwait && role !== Role.PrimeMinister

  const shouldModifyArray = currentMinistryIndex && visibleMinistries < currentMinistryIndex + 1 && isCurrentMinistry

  shouldModifyArray && currentMinistry && shortSlicedData?.push(currentMinistry)

  const shownData = shouldModifyArray ? shortSlicedData : slicedData

  return (
    <ContentBox className={`ranking-ministries ${isExpandedCard && "expanded"}`}>
      <Flex
        align={!isMobile && !isExpandedCard ? "center" : isArab ? "flex-end" : "flex-start"}
        gap={8}
        vertical={isMobile || isExpandedCard}
      >
        <Typography.Title level={2} className={"box-title"}>
          {currentSelectedMinistry?.profile}
        </Typography.Title>
        <Typography.Text className={`mode-tag ${tagColor}`}>{tagText}</Typography.Text>
      </Flex>
      {isStats && (
        <Row className={"stats"} justify={"space-between"}>
          {stats.map((stat, index) => (
            <Col key={index} className={"stats-block"}>
              <Typography.Paragraph className={"stats-title"}>{stat.title}</Typography.Paragraph>
              <Row justify={"space-between"} align={"middle"} style={{ marginTop: isMobile ? 16 : 24 }}>
                <Typography.Paragraph className={"stats-place"}>{stat.value}</Typography.Paragraph>
                <SvgIcon type={stat?.iconType as any} width={40} height={40} className={"stats-icon"} />
              </Row>
              <Typography.Paragraph className={"stats-description"}>{stat.description}</Typography.Paragraph>
            </Col>
          ))}
        </Row>
      )}
      <Row
        className={`ministries-wrapper ${isExpandedCard && "expanded"}`}
        style={{
          direction: isArab ? "rtl" : "ltr",
          backgroundColor: role === "Prime Minister" || role === "Prime Minister of Kuwait" ? "transparent" : "#FAFBFF",
        }}
      >
        {shownData?.map(ministry => (
          <Flex
            vertical
            className={
              isCurrentMinistry && currentSelectedMinistry?.id === ministry?.id
                ? "ministry-block active"
                : "ministry-block"
            }
            key={ministry.profile}
            style={{ direction: "ltr" }}
          >
            <Image
              rootClassName={"ministry-img-root"}
              className={"ministry-img"}
              src={ministry?.p_avatar}
              alt={"Ministry"}
              preview={false}
              style={{
                width: role === "Prime Minister" || role === "Prime Minister of Kuwait" ? 100 : 80,
                height: role === "Prime Minister" || role === "Prime Minister of Kuwait" ? 100 : 80,
              }}
            />
            <Typography.Paragraph className={"ministry-place"}>
              {getOrdinalSuffix(ministry?.overall_rank)}
            </Typography.Paragraph>
            <Typography.Paragraph className={"ministry-name"}>{ministry?.profile}</Typography.Paragraph>
            {isExpandedCard && (
              <div className={"expanded-block-content-wrapper"} style={{ backgroundColor: "#FAFBFF", borderRadius: 8 }}>
                <ExpandedRankingCardContent
                  label={"Evaluation"}
                  value={
                    getEvaluation(
                      getStats(ministry, "Engagement"),
                      getStats(ministry, "Posts"),
                      getStats(ministry, "Fans")
                    ) || "Unknown"
                  }
                  outOf={"100"}
                />
                <Divider />
                <ExpandedRankingCardContent
                  label={"Engagement"}
                  value={getStats(ministry, "Engagement")}
                  outOf={"33.33"}
                />
                <ExpandedRankingCardContent label={"Posts"} value={getStats(ministry, "Posts")} outOf={"33.33"} />
                <ExpandedRankingCardContent label={"Followers"} value={getStats(ministry, "Fans")} outOf={"33.33"} />
              </div>
            )}
          </Flex>
        ))}
      </Row>
      {ministriesData && visibleMinistries < ministriesData?.length && (
        <CustomBtn className={"view-more-btn"} onClick={handleViewMore}>
          View more
        </CustomBtn>
      )}
    </ContentBox>
  )
}
