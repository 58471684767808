import { ConfigProvider } from "antd"
import {
  createContext,
  Dispatch,
  memo,
  SetStateAction,
  useContext,
  useState,
  useEffect,
  type FC,
  type PropsWithChildren,
} from "react"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import routes from "../../pages"
import theme from "../../themes"
import { Role } from "src/types/enums"
import "./index.less"

import { useAuthStore } from "src/store/auth"

type ContextProps = {
  isArab: boolean
  setIsArab: Dispatch<SetStateAction<boolean>>
  role: Role | null
  setRole: Dispatch<SetStateAction<Role | null>>
}

const parseJSON = () => {
  const jsonData = localStorage.getItem("arab")
  return jsonData ? JSON.parse(jsonData) : false
}

const defaultContextValues: ContextProps = {
  isArab: parseJSON(),
  setIsArab: () => null,
  role: localStorage.getItem("role") as Role | null,
  setRole: () => null
}

const Context = createContext(defaultContextValues)

const ContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isArab, setIsArab] = useState(defaultContextValues.isArab)
  const [role, setRole] = useState(defaultContextValues.role)

  return <Context.Provider value={{ isArab, setIsArab, role, setRole }}>{children}</Context.Provider>
}

const useApp: () => ContextProps = () => useContext(Context)


const App: FC = () => {
  const loggedIn = useAuthStore(store => store.loggedIn)
  const setStore = useAuthStore(store => store.set)
  const router = createBrowserRouter(routes(loggedIn))

  useEffect(() => {
    if (localStorage.getItem("jwt") !== null) {
      setStore(store => {
        store.loggedIn = true
      })
    }
  }, [])

  return (
    <ContextProvider>
      <ConfigProvider theme={theme}>
        <RouterProvider router={router} />
      </ConfigProvider>
    </ContextProvider>
  )
}

export { useApp }

export default memo(App)
