import { Button, Col, Dropdown, Flex, Image, MenuProps, Switch, Typography } from "antd"
import { FC } from "react"

import { useApp } from "../../../../app"

import { SvgIcon } from "../../../../../assets/icons"

import avatar from "../../../../../assets/images/avatar.png"

import { useNavigate } from "react-router-dom"
import { logout } from "../../../../../api/logout"
import "./index.less"

export const AccountDropdown: FC<{ setIsOnboarding: (isOnboarding: boolean) => void }> = ({ setIsOnboarding }) => {
  const { isArab, setIsArab } = useApp()
  const navigate = useNavigate()

  const handleArabClick = () => {
    localStorage.setItem("arab", JSON.stringify(!isArab))
    setIsArab(prevState => !prevState)
  }

  const items: MenuProps["items"] = [
    {
      label: (
        <Button
          type={"link"}
          onClick={() => setIsOnboarding(true)}
          className={"account-dropdown-btn"}
          style={{ direction: isArab ? "rtl" : "ltr" }}
        >
          Watch Tutorial
        </Button>
      ),
      key: "0",
    },
    {
      label: (
        <Button
          type={"link"}
          className={"account-dropdown-btn with-switch"}
          style={{ direction: isArab ? "rtl" : "ltr" }}
          onClick={handleArabClick}
        >
          Arab <Switch checked={isArab} size={"small"} />
        </Button>
      ),
      key: "1",
    },
    {
      label: (
        <Button
          type={"link"}
          className={"account-dropdown-btn"}
          style={{ direction: isArab ? "rtl" : "ltr" }}
          onClick={() =>
            logout()
              .then(() => navigate("./login"))
              .catch(e => console.log(e))
          }
        >
          Log out
        </Button>
      ),
      key: "2",
    },
  ]

  return (
    <Dropdown className={"account-dropdown"} menu={{ items }} trigger={["click"]}>
      <Flex style={{ direction: isArab ? "rtl" : "ltr" }} align={"center"} gap={7}>
        <Col>
          <Image preview={false} src={avatar} alt={"Avatar"} width={36} height={36} />
        </Col>
        <Col>
          <Typography.Paragraph className={"user-name"}>Ministry of Health</Typography.Paragraph>
          <Typography.Paragraph className={"user-location"}>The State of Kuwait</Typography.Paragraph>
        </Col>
        <Col>
          <SvgIcon type={"arrowDown"} width={12} height={12} />
        </Col>
      </Flex>
    </Dropdown>
  )
}
