import { Button, Flex, List, Typography } from "antd"
import { FC } from "react"
import { Link, useNavigate } from "react-router-dom"
import { SvgIcon } from "../../../../../assets/icons"
import { Logo } from "../../../../ui/Logo"
import { AccountDropdown } from "../AccountDropdown"
import "./index.less"

interface MobileMenuProps {
  onClose: () => void
  setIsOnboarding: (isOnboarding: boolean) => void
}

const data = [
  {
    title: "Overview",
    href: "/overview",
  },
  {
    title: "Detailed Insights",
    href: "/detailed-insights",
  },
  {
    title: "Ranking & Evaluation",
    href: "/ranking-and-evaluation",
  },
  {
    title: "General Info",
    href: "/general-info",
  },
]

export const MobileMenu: FC<MobileMenuProps> = ({ onClose, setIsOnboarding, ...props }) => {
  const navigate = useNavigate()

  const handleMenuItemClick = (href: string) => {
    navigate(href)
    onClose()
  }

  return (
    <Flex className={"mobile-menu"} vertical {...props}>
      <Flex className={"mobile-header"} justify={"space-between"} align={"center"}>
        <Link to={"/"}>
          <Logo style={{ cursor: "pointer" }} />
        </Link>
        <Button
          className={"mobile-menu-close-btn"}
          icon={<SvgIcon type={"close"} width={24} height={24} />}
          onClick={onClose}
        />
      </Flex>
      <Flex className={"mobile-menu-main"} vertical={true} align={"center"}>
        <List
          dataSource={data}
          renderItem={item => (
            <List.Item>
              <Typography.Paragraph className={"mobile-menu-link"} onClick={() => handleMenuItemClick(item.href)}>
                {item.title}
              </Typography.Paragraph>
            </List.Item>
          )}
          className={"mobile-menu-nav"}
        />
      </Flex>
      <AccountDropdown setIsOnboarding={setIsOnboarding} />
    </Flex>
  )
}
